import PropTypes from "prop-types";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

import "./ExternalEnquiryForm-old.scss";
import "./ExternalEnquiryForm.scss";
import ReCAPTCHA from "react-google-recaptcha";
import cs from "classnames";
import { ReactComponent as WarningIcon } from "../../../assets/svg/warningIconCircle.svg";
import { useWidgetConfig } from "../../../context/widgetConfigContext";
import { getRequest, postRequest } from "../../../services/axios/axiosClient";
import { DISABLED, FAILED, ONE_WAY, SUCCESS } from "../../../constants/otherConstants";
import ExternalEnquiryFormFlightInfoStep from "./steps/ExternalEnquiryFormFlightInfoStep";
import ExternalEnquiryFormPersonalInfoStep from "./steps/ExternalEnquiryFormPersonalInfoStep";
import ExternalEnquiryFormHelicopterOptionsStep from "./steps/ExternalEnquiryFormHelicopterOptionsStep";
import ExternalEnquiryFormSuccess from "./views/ExternalEnquiryFormSuccess";
import PoweredByGetHeli from "../../common/Tags/PoweredByGetHeli";
import { captchaCheck } from "../../../utils/otherUtils";
import secureLocationLatLng from "../../../utils/secureLocationLatLng";
import { initialMapCoordinates } from "../../../constants/mapConfig";
import { deepObjectCompare, isObject } from "../../../utils/objectUtils";
import getEnquiryFormDatesForApi from "./utils/getEnquiryFormDatesForApi";
import { GET_HELI_FURTHER_SECOND_STEP } from "../../../constants/api";
import useMapStore from "../../../store/useMapStore";
import ReactGA from "react-ga4";
import { ReactComponent as SuccessIcon } from "../../../assets/svg/form-success.svg";

const TripInfoErrors = ["from", "to", "return_at", "departure_at", "departure_date", "return_date", "passengers"];
let forceStopAutocomplete = false;
let currentLogId = null;

function ExternalEnquiryForm({
  className,
  defaultLocation,
  isMapUsed,
  formType,
  eventID,
  helicopterOptions,
  setHelicopterOptions,
}) {
  const enquiryFormInstance = useForm({ mode: "onChange" });
  const {
    authData: { token },
    globalState: { captcha },
    setGlobalState,
    setExternalEnquiryFormStep,
  } = useWidgetConfig();

  const setEnquiryFormNextLocation = useMapStore((state) => state.setEnquiryFormNextLocation);
  const enquiryFormNextLocation = useMapStore((state) => state.enquiryFormNextLocation);
  const setMapCoordinates = (fn) =>
    useMapStore.setState(({ mapCoordinates }) => ({ mapCoordinates: fn(mapCoordinates) }));

  const { handleSubmit, setValue, getValues, reset } = enquiryFormInstance;
  const { captchaToken } = captcha;
  const autocompleteReadOnlyData = React.useMemo(() => {
    if (!defaultLocation) return {};
    const {
      location: { category, secondary_text },
    } = defaultLocation;
    const locationText = category ?? "";
    const secondaryText = secondary_text ? " • " + secondary_text : "";
    const selectedPlaceInfo = locationText + secondaryText;

    return {
      dir: defaultLocation?.dir,
      value: defaultLocation?.location.location,
      selectedPlaceInfo,
    };
  }, [defaultLocation]);

  const isEventPage = React.useMemo(() => !!defaultLocation, [defaultLocation]);

  const [formLocations, setFormLocations] = useState({ from_location: {}, to_location: {} });
  const [typeOfTrip, setTypeOfTrip] = useState(ONE_WAY);
  const [formSubmitStatus, setFormSubmitStatus] = useState("");
  const [currentStep, setCurrentStep] = useState(0);
  const [swapLocationsAnimation, setSwapLocationsAnimation] = useState(false);
  const [isLocationSwapped, setIsLocationSwapped] = useState(false);

  const [warningMessages, setWarningMessages] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const recaptchaRef = useRef(null);

  useEffect(() => {
    setGlobalState((prev) => ({
      ...prev,
      enquiryForm: { currentStep },
    }));
  }, [currentStep]);

  useEffect(() => {
    if (getValues("return_date") && typeOfTrip === ONE_WAY) {
      setValue("return_date", null);
    }
  }, [typeOfTrip, getValues, setValue]);

  useEffect(() => {
    setExternalEnquiryFormStep(currentStep);
  }, [currentStep, setExternalEnquiryFormStep]);

  useEffect(() => {
    // add default location to form for readonly autocomplete
    if (defaultLocation) {
      updateLocationHandler(defaultLocation.dir, defaultLocation.location);
      setValue(defaultLocation.dir, defaultLocation.location.location);
    }
  }, [defaultLocation]);

  const changeForceStopAutocomplete = (value) => {
    forceStopAutocomplete = value;
  };

  const handleResetForm = async (e) => {
    /*======================*/
    // if one of the input is readonly, and the location is flipped, flip back
    if (defaultLocation) {
      const isLocationSwapped = deepObjectCompare(
        formLocations[`${defaultLocation.dir}_location`],
        defaultLocation.location,
      );
      if (!isLocationSwapped) {
        await handleLocationsSwap(e);
      }
    }
    /*======================*/
    // reset the form, after submit
    reset();
    setTypeOfTrip(ONE_WAY);
    setCurrentStep(0);
    setSwapLocationsAnimation(false);
    setHelicopterOptions([]);
    setFormSubmitStatus(null);
    setFormLocations({ from_location: {}, to_location: {} });
    setMapCoordinates(() => initialMapCoordinates);

    /*======================*/
    // if one of the input is readonly, and the location is flipped, reset form and
    if (defaultLocation) {
      await updateLocationHandler(defaultLocation.dir, defaultLocation.location);
      setValue(defaultLocation.dir, defaultLocation.location.name || defaultLocation.location.location);
    }
    /*======================*/
  };

  const updateLocationHandler = useCallback(
    async (dir, location) => {
      let updatedLocation = location;

      if (isObject(location)) {
        const updatedPad = await updateMapPad(dir, location);
        updatedLocation = updateCoordsFromGeocoder(location, updatedPad);

        if (updatedPad) {
          isMapUsed &&
            setMapCoordinates((prev) => ({
              ...prev,
              [dir]: updatedPad.coords,
              pad: {
                ...prev.pad,
                [`${dir}_location`]: { ...updatedPad.securedLocation, name: location.name || location.location },
              },
            }));

          const keyword = updatedLocation?.location?.county || updatedLocation?.location?.country;
          if (updatedLocation.custom_message === undefined && typeof updatedLocation.location !== "string" && keyword) {
            const loc = updatedLocation.location;
            await getRequest("get-warning-message", {
              keyword: loc?.county || loc?.country,
              latitude: loc?.latitude,
              longitude: loc?.longitude,
            }).then((res) => {
              updatedLocation = { ...updatedLocation, ...res.data };
            });
          }
        }
      } else if (!isEventPage) {
        isMapUsed &&
          setMapCoordinates((prev) => ({
            ...prev,
            [dir]: null,
            pad: {
              ...prev.pad,
              [`${dir}_location`]: null,
            },
          }));
      }

      await setFormLocations((prevState) => ({ ...prevState, [`${dir}_location`]: updatedLocation }));
    },
    [isMapUsed, setMapCoordinates, setFormLocations, isEventPage],
  );

  useEffect(() => {
    if (enquiryFormNextLocation) {
      forceStopAutocomplete = true;

      enquiryFormInstance.clearErrors(enquiryFormNextLocation.direction);
      setValue(enquiryFormNextLocation.direction, enquiryFormNextLocation.pad.name);
      updateLocationHandler(enquiryFormNextLocation.direction, {
        ...enquiryFormNextLocation.pad,
        latitude: enquiryFormNextLocation.pad.location.latitude,
        longitude: enquiryFormNextLocation.pad.location.longitude,
      });
      setEnquiryFormNextLocation(null);
    }
  }, [enquiryFormNextLocation, setEnquiryFormNextLocation, setValue, updateLocationHandler, enquiryFormInstance]);

  useEffect(() => {
    isMapUsed &&
      setMapCoordinates((prev) => ({
        ...prev,
        typeOfTrip,
      }));
  }, [typeOfTrip, setMapCoordinates]);

  const handleLocationsSwap = async (e) => {
    e?.preventDefault();
    const isDisabled = e?.target.disabled;
    if (isDisabled) return;
    if (e) e.target.disabled = true;
    const { from_location: stateFrom, to_location: stateTo } = formLocations;
    const formFrom = getValues("from");
    const formTo = getValues("to");
    if (!formTo || !formFrom || !stateFrom || !stateTo) {
      if (e) e.target.disabled = false;
      return;
    }
    forceStopAutocomplete = true;
    // ---swap pads on map---
    if (isMapUsed) {
      const swappedPads = await getSwappedPads(stateFrom, stateTo);

      setMapCoordinates((prev) => ({
        ...prev,
        from: swappedPads.fromSwapped?.coords || `${stateTo.latitude}|${stateTo.longitude}`,
        to: swappedPads.toSwapped?.coords || `${stateFrom.latitude}|${stateFrom.longitude}`,
        pad: {
          ...prev.pad,
          from_location: swappedPads.fromSwapped?.securedLocation || {
            ...stateTo,
            name: stateTo.name || stateTo.location,
          },
          to_location: swappedPads.toSwapped?.securedLocation || {
            ...stateFrom,
            name: stateFrom.name || stateFrom.location,
          },
        },
      }));
    }
    //------------------------

    setSwapLocationsAnimation(true);
    setIsLocationSwapped((prevState) => !prevState);
    setValue("from", formTo);
    setValue("to", formFrom);
    setFormLocations({ to_location: stateFrom, from_location: stateTo });

    // swap readonly autocomplete
    if (autocompleteReadOnlyData.dir === "from" && autocompleteReadOnlyData) {
      autocompleteReadOnlyData.dir = "to";
    } else if (autocompleteReadOnlyData.dir === "to" && autocompleteReadOnlyData) {
      autocompleteReadOnlyData.dir = "from";
    }
    setTimeout(() => {
      if (e) e.target.disabled = false;
    }, 300);
    setTimeout(() => setSwapLocationsAnimation(false), 0);
    return true;
  };

  const handleGoToOptions = async () => {
    setIsLoading(true);
    // captcha check
    const isNotRobot = await captchaCheck(recaptchaRef, captchaToken);
    if (!isNotRobot) {
      setIsLoading(false);
      return;
    }
    const data = getEnquiryFormDatesForApi({ formLocations, typeOfTrip, formType, eventID }, getValues);
    const helicopterOptionsResponse = await postRequest("get-heli-options-for-demand-form", data).catch((err) => {
      console.log({ err });
      return { error: err };
    });
    if (helicopterOptionsResponse.error) {
      setIsLoading(false);
      setHelicopterOptions([]);
      setCurrentStep(1);
    }
    const { message, log_id, warning_messages } = helicopterOptionsResponse.data || {
      message: [],
      log_id: null,
      warning_messages: {},
    };
    currentLogId = log_id;
    setIsLoading(false);

    if (!message || !message.length) {
      setHelicopterOptions([]);
    }

    setWarningMessages(warning_messages);
    setHelicopterOptions(message);
    setCurrentStep(1);
  };

  const handleGoToInfo = async (helicopterID) => {
    setCurrentStep(2);
    let data = getEnquiryFormDatesForApi({ formLocations, typeOfTrip, formType, eventID }, getValues);
    data = { ...data, log_id: currentLogId, selected_aircraft: helicopterID };
    await postRequest(GET_HELI_FURTHER_SECOND_STEP, data);
  };

  const handleGoToContact = () => {
    setCurrentStep(2);
  };

  const handlePersonalInfoBack = () => {
    setCurrentStep(currentStep - 1);
  };

  const handleOptionsBack = () => setCurrentStep(0);

  const onSubmit = async () => {
    setIsLoading(true);
    const data = getEnquiryFormDatesForApi({ formLocations, typeOfTrip, formType, currentLogId, eventID }, getValues);

    await postRequest(`pipeline`, data).catch(() => {
      setIsLoading(false);
      setFormSubmitStatus(FAILED);
    });

    setFormSubmitStatus(SUCCESS);
    setCurrentStep(4);
    setIsLoading(false);

    ReactGA.event({
      category: "External Enquiry Form",
      action: "Submit external enquiry form",
      label: "Submit external enquiry form on getheli",
    });
  };

  const isStatusSuccess = formSubmitStatus === SUCCESS;
  const isCurrentStepZero = currentStep === 0;
  const isCurrentStepOne = currentStep === 1;
  const isCurrentStepTwo = currentStep === 2;

  return (
    <div className={cs("gh-widget-external-enquiry-form_wrapper", className)}>
      <div className="gh-widget-external-enquiry-form gh-widget-enquire-form-content">
        {formSubmitStatus === FAILED || !token ? (
          <div className="gh-widget-d-flex gh-widget-flex-align-center gh-widget-font-size-13 gh-widget-padding-15 gh-widget-gray-warning gh-widget-margin-bottom-20">
            <span className="gh-widget-margin-right-10 gh-widget-enquiry-form-warning-icon-wrapper">
              <WarningIcon className="gh-widget-enquiry-form-warning-icon" />
            </span>
            <span className="gh-widget-estimate-warning">Invalid token! Please contact support!</span>
          </div>
        ) : (
          ""
        )}
        <div className={`gh-widget-form-content ${formSubmitStatus === FAILED || !token ? DISABLED : ""}`}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="gh-widget-steps-container">
              <div className="gh-widget-stepper">
                <div className="gh-widget-stepper-step">
                  <div className="gh-widget-stepper-number">
                    {currentStep <= 0 && !isStatusSuccess ? (
                      <span className={isCurrentStepZero ? "active-step" : ""}>1</span>
                    ) : (
                      <SuccessIcon />
                    )}
                  </div>

                  <div className="gh-widget-stepper-step-description">Enquiry</div>
                </div>

                <div className="gh-widget-stepper-step">
                  <div className="gh-widget-stepper-number">
                    {currentStep <= 1 && !isStatusSuccess ? (
                      <span className={isCurrentStepOne ? "active-step" : ""}>2</span>
                    ) : (
                      <SuccessIcon />
                    )}
                  </div>

                  <div className="gh-widget-stepper-step-description">Price options</div>
                </div>

                <div className="gh-widget-stepper-step">
                  <div className="gh-widget-stepper-number">
                    {currentStep <= 2 && !isStatusSuccess ? (
                      <span className={isCurrentStepTwo ? "active-step" : ""}>3</span>
                    ) : (
                      <SuccessIcon />
                    )}
                  </div>

                  <div className="gh-widget-stepper-step-description">Details</div>
                </div>
              </div>

              <ExternalEnquiryFormSuccess
                show={isStatusSuccess}
                handleResetForm={handleResetForm}
              />
              <ExternalEnquiryFormFlightInfoStep
                show={isCurrentStepZero}
                enquiryFormInstance={enquiryFormInstance}
                updateLocationHandler={updateLocationHandler}
                forceStopAutocomplete={forceStopAutocomplete}
                formLocations={formLocations}
                swapLocationsAnimation={swapLocationsAnimation}
                handleLocationsSwap={handleLocationsSwap}
                typeOfTrip={typeOfTrip}
                setTypeOfTrip={setTypeOfTrip}
                changeForceStopAutocomplete={changeForceStopAutocomplete}
                setCurrentStep={setCurrentStep}
                TripInfoErrors={TripInfoErrors}
                handleGoToOptions={handleGoToOptions}
                isLoading={isLoading}
                autocompleteReadOnlyData={autocompleteReadOnlyData}
                isStatusSuccess={isStatusSuccess}
              />
              <ExternalEnquiryFormHelicopterOptionsStep
                show={isCurrentStepOne}
                helicopterOptions={helicopterOptions}
                handleGoToInfo={handleGoToInfo}
                handleGoToContact={handleGoToContact}
                handleOptionsBack={handleOptionsBack}
                typeOfTrip={typeOfTrip}
                warningMessages={warningMessages}
              />
              <ExternalEnquiryFormPersonalInfoStep
                show={isCurrentStepTwo}
                enquiryFormInstance={enquiryFormInstance}
                isLoading={isLoading}
                handlePersonalInfoBack={handlePersonalInfoBack}
              />
            </div>
          </form>
        </div>
        <PoweredByGetHeli />
        {captchaToken && (
          <ReCAPTCHA
            sitekey={captchaToken}
            ref={recaptchaRef}
            size="invisible"
          />
        )}
      </div>
    </div>
  );
}

ExternalEnquiryForm.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
  }),
  className: PropTypes.string,
  isMapUsed: PropTypes.bool,
  defaultLocation: PropTypes.shape({
    dir: PropTypes.oneOf(["from", "to"]),
    location: PropTypes.object,
  }),
  formType: PropTypes.string.isRequired,
  eventID: PropTypes.string,
  helicopterOptions: PropTypes.array,
  setHelicopterOptions: PropTypes.func,
};

ExternalEnquiryForm.defaultProps = { className: "", defaultLocation: null, isMapUsed: false };

export default React.memo(ExternalEnquiryForm);

const updateMapPad = async (dir, location) => {
  if (!location) return;

  try {
    const securedLocation = await secureLocationLatLng(location);
    const coords = `${securedLocation.latitude}|${securedLocation.longitude}`;
    return {
      coords,
      securedLocation,
    };
  } catch (error) {
    toast.error(error);
    return null;
  }
};

const getSwappedPads = async (stateFrom, stateTo) => {
  const toSwapped = await updateMapPad("to", stateFrom);
  const fromSwapped = await updateMapPad("from", stateTo);

  return {
    toSwapped,
    fromSwapped,
  };
};

const updateCoordsFromGeocoder = (location, locationFromGeocoder) => {
  let updatedLocation = location;
  if (!location?.latitude || !location?.longitude) {
    const latitudeFromGeocoder = parseFloat(locationFromGeocoder?.coords.split("|")[0]);
    const longitudeFromGeocoder = parseFloat(locationFromGeocoder?.coords.split("|")[1]);
    if (!latitudeFromGeocoder || !longitudeFromGeocoder) {
      return;
    }

    updatedLocation = { ...location, latitude: latitudeFromGeocoder, longitude: longitudeFromGeocoder };
  }
  return updatedLocation;
};
