import PropTypes from "prop-types";
import React, { useState } from "react";
import Map from "./Map";
import { getPadsInRadius } from "./Map.utils";
import usePrevious from "../../../hooks/usePrevious";
import { getHelipaddySites, getMapEventsList } from "./PadSelection.utils";
import useMapStore from "../../../store/useMapStore";
import { useWidgetConfig } from "../../../context/widgetConfigContext";

function PadsSelection(props) {
  const setEnquiryFormNextLocation = useMapStore((state) => state.setEnquiryFormNextLocation);
  const {
    globalState: { enquiryForm },
  } = useWidgetConfig();
  const { flyCoordinates, showPadsInRadius } = props;
  const [padsInRadius, setPadsInRadius] = React.useState([]);
  const [mapPinsLoaded, setMapPinsLoaded] = React.useState(false);
  const [landingSites, setLandingSites] = useState(null);

  const formCurrentStep = enquiryForm?.currentStep;

  const isCurrentStepZero = formCurrentStep === 0;

  const previousFlyCoordinates = usePrevious(flyCoordinates || []);

  React.useEffect(() => {
    const fetchLandingSites = () => {
      Promise.allSettled([getMapEventsList()/*, getHelipaddySites()*/]).then((results) => {
        const sites = [];

        results.forEach((result) => {
          if (result.status === "fulfilled") {
            sites.push(...result.value);
          }
        });

        setLandingSites(sites);

        setMapPinsLoaded(true);
      });
    };

    if (showPadsInRadius) {
      fetchLandingSites();
    }
  }, [showPadsInRadius]);

  React.useEffect(() => {
    if (!landingSites) return;
    const pads = getPadsInRadius(previousFlyCoordinates?.[0], flyCoordinates?.[0], !padsInRadius?.length, landingSites);

    pads.map((pad) => {
      pad.handleAssignButton = (direction) => setEnquiryFormNextLocation({ direction, pad });
      return pad;
    });

    isCurrentStepZero ? setPadsInRadius(pads) : setPadsInRadius([]);
    setPadsInRadius(pads);
  }, [
    flyCoordinates,
    previousFlyCoordinates,
    padsInRadius?.length,
    setEnquiryFormNextLocation,
    landingSites,
    isCurrentStepZero,
  ]);

  if (!window.google) {
    return null;
  }

  return (
    <Map
      pads={mapPinsLoaded && showPadsInRadius ? padsInRadius : []}
      allowCustomMarker={showPadsInRadius}
      flyCoordinates={flyCoordinates}
      assignAction={setEnquiryFormNextLocation}
      sites={landingSites}
    />
  );
}

PadsSelection.propTypes = {
  flyCoordinates: PropTypes.array,
  showPadsInRadius: PropTypes.bool,
};

PadsSelection.defaultProps = {
  flyCoordinates: [],
  showPadsInRadius: true,
};

export default React.memo(PadsSelection, (prevProps, nextProps) => {
  return JSON.stringify(prevProps) === JSON.stringify(nextProps);
});
